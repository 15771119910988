
declare const Buffer: {
  from: (
    data: string,
    encode: string
  ) => { toString: (encode: string) => string };
}; /// <reference types="node" /> (add this to top, npm i -D @types/node)
import { Options, Vue } from "vue-class-component";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import SelectButton from "primevue/selectbutton";
import AuthService from "@/service/auth";
// import { namespace } from "vuex-class";

// const currentUser = namespace("currentUser");

@Options({
  components: { Message, InputText, Dialog, InputMask, SelectButton },
  watch: {
    email: {
      handler: "validEmail",
      immediate: true,
    },
    password: {
      handler: "validPassword",
      immediate: true,
    },
    selectedCodeType: {
      handler: "codeLabels",
    },
  },
})
export default class LoginForm extends Vue {
  declare $refs: {
    codeInput: HTMLFormElement;
  };
  error = "";
  email = "";
  password = "";
  // email = "tcweb@steiner.cz";
  // password = "ahoj";
  isEmail = true;
  isPassword = true;
  showCode = false;
  allowedCodes: { name: string; code: number }[] = [];
  code = ""; // 885456
  codeSent = false;
  codeDisableSend = false;
  wrongCode = false;
  selectedCodeType = -1;
  forgotPasswordDialog = false;
  codeButtonLabel = "Send code";
  codeHintLabel = "Write code";
  setTOTP = false; // Setup google button enable
  googleAuthImage = "";

  public async login() {
    this.error = "";
    await AuthService.auth(
      { username: this.email, password: this.password },
      this.selectedCodeType >= 0 ? this.selectedCodeType : undefined
    )
      .then((r) => {
        // '' = nofa
        // {1-EMAIL: true, 0-TOTP: false} = mfa
        // console.log(r.data);
        if (r.data) {
          this.setAllowedCodeTypes(r.data);
          this.showCode = true;
        } else this.$router.push("/"); //TODO google setup
      })
      .catch((r) => {
        // this.password = ""; // ?
        //TODO Be not responding error
        //! Server sometimes returns 500 when password is not correct
        // if (r.response.status == 403) {
          this.error =
            "Wrong password. Try again or click Forgot password to reset it.";
        // } else this.catchError();
      });
  }

  public async sendCode() {
    this.error = "";
    this.code = "";
    this.codeSent = false;
    this.codeDisableSend = true;
    this.wrongCode = false;
    await AuthService.auth(
      { username: this.email, password: this.password },
      this.selectedCodeType
    )
      .then(() => {
        this.codeSent = true;
        this.$nextTick(() => this.$refs.codeInput.$el.focus());
      })
      .catch(() => {
        // console.log(r.response.status);
        this.catchError();
      });
    this.codeDisableSend = false;
  }

  public async sign() {
    await AuthService.sign(
      { username: this.email, password: this.password },
      +this.code,
      this.selectedCodeType
    )
      .then(() => {
        // console.log("Sign: ", r);
        this.$router.push("/");
      })
      .catch((r) => {
        // console.log(r);
        if (r.response.status != 403) return this.catchError();
        this.wrongCode = true;
        this.code = "";
        this.codeSent = false;
      });
  }

  catchError() {
    this.error = "Server error: Please contact support atlantaadm@steiner.cz";
  }

  setAllowedCodeTypes(v: Record<string, boolean>) {
    // {1-EMAIL: true, 0-TOTP: false}
    this.allowedCodes = [];
    Object.keys(v).forEach((k: string) => {
      switch (k) {
        case "0-TOTP":
          this.allowedCodes.push({ name: "TOTP", code: 0 });
          this.setTOTP = !v[k];
          break;
        case "1-EMAIL":
          if (v[k]) this.allowedCodes.push({ name: "Email", code: 1 });
          break;
        case "2-SMS":
          if (v[k]) this.allowedCodes.push({ name: "SMS", code: 2 });
          break;
      }
    });
    if (this.allowedCodes.length)
      this.selectedCodeType = this.allowedCodes[0].code;
  }

  async googleAuth() {
    await AuthService.googleAuth({
      username: this.email,
      password: this.password,
    })
      .then((response) => {
        this.googleAuthImage = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        this.setTOTP = false;
      })
      .catch(() => this.catchError());
  }

  validEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const b = re.test(String(this.email).toLowerCase());
    this.isEmail = b;
  }

  validPassword() {
    this.isPassword = !!this.password;
  }

  codeLabels() {
    this.wrongCode = false;
    switch (this.selectedCodeType) {
      case 0: // 0-TOTP
        this.codeButtonLabel = "Activate TOTP Login";
        this.codeHintLabel =
          "Please input the 6 digit code found in the Google Authenticator application";
        break;
      case 1: // 1-EMAIL
        this.codeButtonLabel = "Send code to email";
        this.codeHintLabel =
          "Please input the 6 digit code found in your email";
        break;
      case 2: // 2-SMS
        this.codeButtonLabel = "Send code to SMS";
        this.codeHintLabel = "Please input the 6 digit code found in the SMS";
        break;
    }
  }
}
